import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/layout/layout';
import LatestArticle from '../../components/latest-articles';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout title="Articles" description="A short curated selection of articles I've written." type="📚" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In my writing I like to explore art, writing, technology, and the areas where any of my interests intersect.`}</p>
    <h2 {...{
      "id": "latest-articles"
    }}>{`Latest Articles`}</h2>
    <p>{`Here are the latest articles I’ve published on my website.`}</p>
    <LatestArticle mdxType="LatestArticle" />
    <p><a parentName="p" {...{
        "href": "/articles/all"
      }}>{`See all posts `}{`→`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/tags"
      }}>{`See all tags `}{`→`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      